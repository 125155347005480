import ImageWithBackup from 'components/ImageWithBackup'
import React, { FC, ReactNode, useMemo } from 'react'

export interface StreetViewImageProps {
  fov?: number
  lat?: string
  long?: string
  location?: string
  heading?: number
  width?: number
  height?: number
  pitch?: number
  className?: string
  retina?: boolean
  backup?: ReactNode
}

const StreetViewImage: FC<StreetViewImageProps> = ({
  className,
  fov,
  width = 350,
  height = 350,
  lat,
  location,
  long,
  heading,
  pitch,
  backup,
}) => {
  const queryParams = useMemo(() => {
    const key = typeof window !== 'undefined' ? document.body.dataset.googleMapsApiKey : process.env.GOOGLE_MAPS_API_KEY
    const params = {
      size: `${width}x${height}`,
      location: location ?? `${lat},${long}`,
      fov,
      heading,
      pitch,
      key,
      return_error_code: !!backup,
    }
    return Object.entries(params)
      .filter(([_, value]) => value != null)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
  }, [width, height, location, lat, long, fov, heading, pitch, backup])

  const url = useMemo(() => {
    return `https://maps.googleapis.com/maps/api/streetview?${queryParams}`
  }, [queryParams])

  return <ImageWithBackup className={className} primary={url} backup={backup} />
}

export default StreetViewImage
