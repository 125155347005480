import React, { FC } from 'react'

interface Props {
  className?: string
  strokeWidth?: number
}

const Spinner: FC<Props> = ({ className, strokeWidth = 8 }) => (
  <svg
    className={className}
    width={`${100 + strokeWidth}px`}
    height={`${100 + strokeWidth}px`}
    viewBox={`0 0 ${100 + strokeWidth} ${100 + strokeWidth}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform={`translate(${strokeWidth / 2}, ${strokeWidth / 2})`}>
      <path
        strokeWidth={`${strokeWidth}`}
        fill="none"
        strokeLinecap="round"
        stroke="currentColor"
        d="M86.6507364,84.0109567 C94.9346702,75.0881927 100,63.1356319 100,50 C100,22.3857625 77.6142375,0 50,0 C22.3857625,0 0,22.3857625 0,50 C0,62.7690101 4.786524,74.4200676 12.6631998,83.2568003"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0 50 50"
          to="360 50 50"
          dur="1s"
          repeatCount="indefinite"
        />
      </path>
    </g>
  </svg>
)

export default Spinner
