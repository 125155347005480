import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" height="14" width="14" className={className}>
    <path
      d="M9.6775 4.83887L7 7.51053L4.3225 4.83887L3.5 5.66137L7 9.16137L10.5 5.66137L9.6775 4.83887Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
