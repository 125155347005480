import { Property, PropertyHead } from 'recoil/properties'
import { formatPropertyAddress } from 'utils/formatting'

export const buildableArea = (property: Property): number | undefined => {
  if (!property.zoned_buildable_area) {
    return
  }

  if (!property.zoned_buildable_area.remaining_square_footage) {
    return property.zoned_buildable_area.remaining_square_footage || undefined
  }

  return property.zoned_buildable_area.remaining_square_footage > 0
    ? property.zoned_buildable_area.remaining_square_footage
    : 0
}

export const amountOverbuilt = (property: Property): number => {
  if (!property.zoned_buildable_area || !property.zoned_buildable_area.remaining_square_footage) {
    return 0
  }
  return property.zoned_buildable_area.remaining_square_footage > 0
    ? 0
    : -property.zoned_buildable_area.remaining_square_footage
}

export const displayAddress = (property: Property): string => {
  return [property.delivery_line_1, property.city, property.state, property.zip5].join(', ')
}

export const getPropertyMapUrl = (
  property: PropertyHead | undefined,
  width = 1000,
  height = 1000
): string | undefined => {
  if (!property) return
  // Mapbox static image API doesn't support searching by address. We don't want to geocode ourselves because $$, but google maps supports it at no additional cost
  const hasLatLng = property?.lat != null && property.lat != '' && property?.long != null && property.long != ''
  const mapboxUrl = `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${property.long},${property.lat},17,0/${width}x${height}@2x?access_token=pk.eyJ1Ijoicm9od2VpZ2VsIiwiYSI6ImNrYmg1YWh2YzAyMjkyem5yN2RsMmRpZXkifQ.STA87m4W37moWj2evrjymw`
  const googleMapsUrls = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURI(
    formatPropertyAddress(property)
  )}&zoom=16&scale=2&maptype=satellite&size=${width}x${height}&key=AIzaSyDIABYI0DmyZHUfhoJxVXKDXJ_4znAEE8E`

  return hasLatLng ? mapboxUrl : googleMapsUrls
}

export const getGoogleMapsUrl = (propertyInfo: {
  line1: string
  city: string
  state: string
  zip5: string
  lat: string | number
  long: string | number
}): string => {
  return `https://maps.google.com/?q=${[propertyInfo.line1, propertyInfo.city, propertyInfo.state, propertyInfo.zip5].join(', ').replace(/ /g, '+')}&ll=${propertyInfo.lat},${propertyInfo.long}`
}
