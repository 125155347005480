import React, {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  FocusEvent,
  useState,
  KeyboardEvent,
  ReactNode,
  FC,
  ChangeEvent,
  useImperativeHandle,
  ForwardedRef,
  useRef,
} from 'react'
import MaskedInput, { Mask } from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { FloatingPortal, size, useDismiss, useFloating, useInteractions } from '@floating-ui/react'
import type { ElementProps } from '@floating-ui/react'
import debounce from 'lodash.debounce'
import cx from 'classnames'
import CheckBox from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank'
import { autocompleteGooglePlaces } from 'apis/googlePlaces'
import { useFormattedPhoneInput } from 'components/Core/useFormattedPhoneInput'
import CoreText from './CoreText'
import styles from './CoreInput.module.scss'
import ExpandMore from 'svgs/expand-more'

// https://github.com/text-mask/text-mask/tree/master/addons/#createnumbermask
const currencyMask: Mask = createNumberMask({ allowDecimal: true, allowLeadingZeroes: true })

interface WrapperProps {
  className?: string
  hint?: string
  hintClassName?: string
  onChangeText?: (value: string) => void
  kind?: 'enabled' | 'alert' | 'positive' | 'disabled'
  id?: string
  label?: string
  borderType?: 'bounded' | 'boundless'
  elementSize?: 'l' | 'xl'
}

const Wrapper: FC<PropsWithChildren<WrapperProps>> = ({
  hint,
  hintClassName,
  className,
  kind = 'enabled',
  children,
  id,
  label,
  borderType = 'bounded',
  elementSize = 'xl',
}) => {
  return (
    <>
      <div className={cx(styles.wrapper, kind, borderType, elementSize, className)}>
        <div className={styles.inputWrapper}>
          {children}
          <label htmlFor={id}>{label}</label>
        </div>
        {hint && (
          <CoreText.Paragraph size="s" color="tertiaryBlack" weight="light" className={cx(styles.hint, hintClassName)}>
            {hint}
          </CoreText.Paragraph>
        )}
      </div>
    </>
  )
}

const InnerPhoneInput: ForwardRefRenderFunction<
  HTMLInputElement,
  { onChangeText?: (value: string) => void } & JSX.IntrinsicElements['input']
> = ({ onChange, onChangeText, value, ...props }, forwardedRef) => {
  const [phoneValue, setPhoneValue] = useState<string>()

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event)
    },
    [onChange]
  )

  const handleChangedText = useCallback(
    (value: string) => {
      setPhoneValue(value)
      onChangeText?.(value)
    },
    [onChangeText]
  )

  const handlePhoneReset = useCallback(() => {
    setPhoneValue('')
    onChangeText?.('undefined')
  }, [onChangeText])

  const { formattedValue, ref, handlePhoneNumberChanged } = useFormattedPhoneInput(
    (value || phoneValue)?.toString() || '',
    handleChange,
    handleChangedText
  )

  useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement)

  return (
    <input
      ref={ref}
      className={styles.input}
      onChange={handlePhoneNumberChanged}
      onReset={handlePhoneReset}
      {...props}
      value={formattedValue}
    />
  )
}

const PhoneInput = forwardRef(InnerPhoneInput)

export type InputProps = WrapperProps & JSX.IntrinsicElements['input']

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    value,
    hint,
    hintClassName,
    kind,
    className,
    onChange,
    onChangeText,
    id,
    label,
    placeholder,
    borderType,
    elementSize,
    ...props
  },
  ref
) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event)
      onChangeText?.(event.target.value)
    },
    [onChange, onChangeText]
  )

  return (
    <Wrapper
      hint={hint}
      hintClassName={hintClassName}
      label={label}
      borderType={borderType}
      kind={kind}
      elementSize={elementSize}
      id={id}
      className={cx(styles.wrapper, className)}
    >
      {props.type == 'tel' ? (
        <PhoneInput
          id={id}
          placeholder={placeholder || label}
          className={styles.input}
          onChange={handleChange}
          onChangeText={onChangeText}
          {...props}
          ref={ref}
        />
      ) : (
        <input
          id={id}
          placeholder={placeholder || label}
          value={value}
          className={styles.input}
          onChange={handleChange}
          {...props}
          ref={ref}
        />
      )}
    </Wrapper>
  )
}

function mergeRefs<T>(...refs: React.Ref<T>[]): React.Ref<T> {
  return (instance: T | null) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(instance)
      } else if (ref && 'current' in ref) {
        ;(ref as React.MutableRefObject<T | null>).current = instance
      }
    })
  }
}

export type MoneyInputProps = WrapperProps & JSX.IntrinsicElements['input']

const MoneyInput: ForwardRefRenderFunction<HTMLInputElement, MoneyInputProps> = (
  {
    value,
    hint,
    hintClassName,
    kind,
    className,
    onChange,
    onChangeText,
    id,
    label,
    placeholder,
    borderType,
    elementSize,
    ...props
  },
  ref
) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event)
      onChangeText?.(event.target.value.replace(/[$,]/g, ''))
    },
    [onChange, onChangeText]
  )

  const renderInput = useCallback(
    (innerRef: ForwardedRef<HTMLInputElement>, props: JSX.IntrinsicElements['input']) => {
      const combinedref = mergeRefs(ref, innerRef)
      return <input ref={combinedref} {...props} />
    },
    [ref]
  )

  return (
    <Wrapper
      hint={hint}
      hintClassName={hintClassName}
      label={label}
      borderType={borderType}
      kind={kind}
      elementSize={elementSize}
      id={id}
      className={cx(styles.wrapper, className)}
    >
      <MaskedInput
        mask={currencyMask}
        id={id}
        placeholder={placeholder || label}
        value={value}
        className={styles.input}
        onChange={handleChange}
        inputMode="numeric"
        {...props}
        ref={undefined}
        render={renderInput as any}
      />
    </Wrapper>
  )
}

type TextAreaProps = WrapperProps & JSX.IntrinsicElements['textarea'] & { autoResizeOnFocus?: boolean }

const TextArea: ForwardRefRenderFunction<HTMLTextAreaElement, TextAreaProps> = (
  {
    hint,
    hintClassName,
    kind,
    className,
    onChange,
    onChangeText,
    rows: rowsProp,
    label,
    elementSize,
    placeholder,
    borderType,
    id,
    autoResizeOnFocus,
    ...props
  },
  ref
) => {
  const internalRef = useRef<HTMLTextAreaElement>(null)
  useImperativeHandle(ref, () => internalRef.current as HTMLTextAreaElement)
  const rows = rowsProp || 4

  const setAutoHeight = useCallback((element) => {
    element.style.height = `${element.scrollHeight}px`
  }, [])

  const removeHeight = useCallback((element) => {
    element.style.height = ''
  }, [])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (autoResizeOnFocus) setAutoHeight(event.target)
      onChange?.(event)
      onChangeText?.(event.target.value)
    },
    [autoResizeOnFocus, setAutoHeight, onChange, onChangeText]
  )

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      props.onFocus?.(event)
      if (autoResizeOnFocus) setAutoHeight(event.target)
    },
    [autoResizeOnFocus, setAutoHeight, props]
  )

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      props.onBlur?.(event)
      if (autoResizeOnFocus) removeHeight(event.target)
    },
    [autoResizeOnFocus, props, removeHeight]
  )

  return (
    <Wrapper
      hint={hint}
      hintClassName={hintClassName}
      label={label}
      id={id}
      kind={kind}
      borderType={borderType}
      elementSize={elementSize}
      className={cx(styles.wrapper, styles.ta, className)}
    >
      <textarea
        id={id}
        placeholder={placeholder || label}
        className={styles.input}
        rows={rows}
        onChange={handleChange}
        ref={internalRef}
        {...props}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Wrapper>
  )
}

type SelectProps = WrapperProps & { allowBlank?: boolean; placeholder?: string } & JSX.IntrinsicElements['select']

const Select: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  {
    hint,
    hintClassName,
    kind,
    className,
    onChange,
    onChangeText,
    label,
    placeholder,
    id,
    allowBlank,
    children,
    borderType,
    elementSize,
    ...props
  },
  ref
) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      onChange?.(event)
      onChangeText?.(event.target.value)
    },
    [onChange, onChangeText]
  )

  return (
    <Wrapper
      hint={hint}
      hintClassName={hintClassName}
      label={label}
      id={id}
      kind={kind}
      borderType={borderType}
      elementSize={elementSize}
      className={cx(styles.wrapper, className)}
    >
      <ExpandMore className={styles.accessory} />
      <select id={id} className={styles.input} onChange={handleChange} {...props} ref={ref}>
        {(allowBlank || !!placeholder) && (
          <option value="" disabled={!allowBlank} hidden={!allowBlank}>
            {allowBlank ? '' : placeholder}
          </option>
        )}
        {children}
      </select>
    </Wrapper>
  )
}

interface AddressProps extends Omit<InputProps, 'value' | 'onChange'> {
  onChangePrediction?: (prediction: { address: string; place_id: string } | undefined) => void
  right?: ReactNode
}

const Address: ForwardRefRenderFunction<HTMLInputElement, AddressProps> = (
  { name, onChangePrediction, onBlur, right, ...rest },
  ref
) => {
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery, setDebouncedQuery] = useState<string>(query)
  const [predictions, setPredictions] = useState<{ address: string; place_id: string }[]>([])
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [selected, setSelected] = useState<{ address: string; place_id: string }>()
  const [referenceWidth, setReferenceWidth] = useState<number>()
  const [keyboardHover, setKeyboardHover] = useState<number>()

  const { context, x, y, refs, strategy, update } = useFloating({
    open: popoverOpen,
    onOpenChange: setPopoverOpen,
    placement: 'bottom',
    middleware: [
      size({
        apply({ rects }) {
          setReferenceWidth(rects.reference.width)
        },
      }),
    ],
  })

  const interactions = [useDismiss(context)].filter((e) => e) as ElementProps[]

  const { getReferenceProps, getFloatingProps } = useInteractions(interactions)

  const handleChangeText = useCallback((value: string) => {
    setQuery(value)
    setSelected(undefined)
  }, [])

  const handleFocus = useCallback(() => setPopoverOpen(true), [])

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      e.stopPropagation()
      e.preventDefault()

      if (!selected) setQuery('')
      onBlur?.call(e)
    },
    [selected, onBlur]
  )

  const handleSelectPrediction = useCallback(
    (prediction) => () => {
      setSelected(prediction)
      setQuery('')
    },
    []
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (!predictions.length) return

      if (e.code == 'ArrowDown') {
        const next = keyboardHover === undefined ? 0 : Math.min(predictions.length - 1, keyboardHover + 1)
        setKeyboardHover(next)
      } else if (e.code == 'ArrowUp') {
        const next = keyboardHover === undefined ? undefined : Math.max(0, keyboardHover - 1)
        setKeyboardHover(next)
      } else if (e.code == 'Enter') {
        e.preventDefault()
        if (keyboardHover !== undefined) {
          handleSelectPrediction(predictions[keyboardHover])()
          setKeyboardHover(undefined)
        }
      }
    },
    [predictions, keyboardHover, handleSelectPrediction]
  )

  useEffect(() => {
    if (popoverOpen) update()
  }, [update, popoverOpen, referenceWidth])

  useEffect(() => {
    const update = debounce((value: string) => setDebouncedQuery(value), 200)
    update(query)
    return () => update.cancel()
  }, [query])

  useEffect(() => {
    if (!debouncedQuery.length) {
      return setPredictions([])
    }

    const _ = async () => {
      try {
        const results = await autocompleteGooglePlaces(debouncedQuery)
        setPredictions(results.items)
      } catch (e) {
        console.error(e)
      }
    }
    _()
  }, [debouncedQuery])

  useEffect(() => setPopoverOpen(predictions && !!predictions.length), [predictions])
  useEffect(() => onChangePrediction?.(selected), [selected, onChangePrediction])

  useEffect(() => {
    if (selected) {
      setQuery('')
    }
  }, [selected])

  useEffect(() => {
    if (query.length) {
      setSelected(undefined)
    }
  }, [query.length])

  return (
    <>
      <input
        name={name}
        className={cx({ 'tw-font-bold': !!selected })}
        type="hidden"
        value={selected ? `place_id:${selected.place_id}` : ''}
      />
      <div {...getReferenceProps({ ref: refs.setReference, className: 'tw-relative' })}>
        <CoreInput.Text
          {...rest}
          ref={ref}
          value={selected ? selected.address.split(', ').slice(0, -1).join(', ') : query}
          onChangeText={handleChangeText}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        {!!right && (
          <div className="tw-absolute tw-top-1/2 tw--translate-y-1/2 tw-right-1 tw-w-11 tw-h-11 tw-flex tw-items-center tw-justify-center">
            {right}
          </div>
        )}
      </div>
      <FloatingPortal>
        {popoverOpen && (
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
                width: referenceWidth,
              },
              onMouseDown: (e) => {
                e.preventDefault() // Prevent the mouse down event from blurring the input
              },
            })}
          >
            <div className="tw-bg-white tw-rounded-xl tw-drop-shadow-lg">
              {predictions.map((prediction, i) => {
                const [line1, ...rest] = prediction.address.split(', ')
                const line2 = rest.join(', ')

                return (
                  <button
                    type="button"
                    className={cx(
                      'tw-w-full tw-flex tw-flex-col tw-p-3 tw-gap-0.5 tw-text-md tw-bg-white hover:tw-bg-neutrals-100 tw-text-left',
                      { '!tw-bg-neutrals-100': i == keyboardHover }
                    )}
                    key={`prediction_${i}`}
                    onClick={handleSelectPrediction(prediction)}
                  >
                    <div className="tw-font-semibold">{line1}</div>
                    <div className="tw-text-disabled-black">{line2}</div>
                  </button>
                )
              })}
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  )
}

const Check: ForwardRefRenderFunction<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & { label: string; id: string }
> = ({ label, id, checked, ...props }, ref) => {
  return (
    <div className="tw-flex tw-items-center tw-rounded tw-p-2 hover:tw-bg-neutrals-100">
      <div className="tw-relative tw-flex tw-items-center tw-justify-center">
        <input
          type="checkbox"
          checked={checked}
          {...props}
          id={id}
          ref={ref}
          className="tw-inset-0 tw-absolute tw-opacity-0"
        />
        {checked ? (
          <CheckBox className="!tw-size-4 tw-relative tw-pointer-events-none tw-text-green-700" />
        ) : (
          <CheckBoxOutlineBlank className="!tw-size-4 tw-relative tw-pointer-events-none" />
        )}
      </div>
      <label className="tw-grow tw-pl-1 tw-select-none" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

const CoreInput = {
  Text: forwardRef(Input),
  Money: forwardRef(MoneyInput),
  TextArea: forwardRef(TextArea),
  Select: forwardRef(Select),
  Address: forwardRef(Address),
  Check: forwardRef(Check),
}

export default CoreInput
