import React, { FC, PropsWithChildren } from 'react'
import cx from 'classnames'

import styles from './CoreText.module.scss'

interface CoreTextProps {
  className?: string
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  weight?: 'regular' | 'heavy' | 'light'
  color?: 'primaryBlack' | 'secondaryBlack' | 'tertiaryBlack' | 'secondary' | 'disabledBlack' | 'primaryWhite'
  element?: keyof JSX.IntrinsicElements
}

const Headline: FC<PropsWithChildren<CoreTextProps>> = ({
  children,
  className,
  size = 'm',
  weight = 'regular',
  color,
  element: Element = 'h1',
}) => {
  return <Element className={cx(styles.text, styles.headline, size, weight, color, className)}>{children}</Element>
}

const Paragraph: FC<PropsWithChildren<CoreTextProps>> = ({
  children,
  className,
  size = 'm',
  weight = 'regular',
  color,
  element: Element = 'p',
}) => {
  return <Element className={cx(styles.text, styles.paragraph, size, weight, color, className)}>{children}</Element>
}

const CoreText = {
  Headline: Headline,
  Paragraph: Paragraph,
}

export default CoreText
