import React, { FC } from 'react'
import cx from 'classnames'

import styles from './Spinner.module.scss'

export type IconSize = 'sm' | 'md' | 'lg' | 'xl'
export type IconColor = 'white'

export interface SpinnerProps {
  size?: IconSize
  color?: IconColor
}

export const Spinner: FC<SpinnerProps> = ({ size, color }) => {
  return (
    <div className={styles.spinner}>
      <i className={cx(styles[`spinner-icon${color ? '-white' : ''}`], styles[`spinner-icon--${size || 'sm'}`])} />
    </div>
  )
}

export default Spinner
