import classNames from 'classnames'
import React, { ReactNode, useEffect, useState } from 'react'
import { FC } from 'react'

import styles from './ImageWithBackup.module.scss'

export interface ImageWithBackupProps {
  primary: string
  backup: ReactNode
  type?: string
  className?: string
}

const ImageWithBackup: FC<ImageWithBackupProps> = ({ primary, backup, type, className }) => {
  const [error, setError] = useState(false)

  type = type || 'image/jpeg'

  // on Safari (and some other browsers), <object> won't failover properly
  // This effect will trigger a failover for those browsers.
  useEffect(() => {
    const runMe = async () => {
      const response = await fetch(primary)
      if (response.status < 200 || response.status >= 300) {
        setError(true)
      }
    }
    runMe()
  }, [primary])

  if (error) {
    return <div className={classNames(styles.imageWithBackup, className)}>{backup}</div>
  }

  return (
    <object data={primary} type={type} className={classNames(styles.imageWithBackup, className)}>
      {backup}
    </object>
  )
}

export default ImageWithBackup
