import { FC, useEffect } from 'react'

export interface PageTitleProps {
  title: string
}

const PageTitle: FC<PageTitleProps> = ({ title }) => {
  useEffect(() => {
    document.title = `${title} | Realm`
  }, [title])

  return null
}

export default PageTitle
